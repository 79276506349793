import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as userService from "../../services/userService";
import swal from "sweetalert";

const Register = () => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    collection: [
      {
        id: 6,
        quoteContent:
          "Welcome to MyQuoteCollection! Click more hearts to add to your collection.",
        authorId: 1,
        sourceId: 17,
        rating: 10,
        ratingAggregate: 10,
        ratingCount: 1,
        fullName: "Welcome Bot",
        tags: [{ id: 11, name: "Love" }],
      },
    ],
    ratingHistory: [{ QuoteId: 6, RatingValue: 10 }],
  });

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRegister = (event) => {
    event.preventDefault();

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailPattern.test(formData.email)) {
      setIsValidEmail(false);
      return;
    } else if (formData.password !== formData.confirmPassword) {
      setIsValidPassword(false);
      return;
    } else {
      userService.addUser(formData).then(onAddSuccess).catch(onAddError);
    }
  };

  const onAddSuccess = (response) => {
    swal("Registration Successful", "Thank you!", "success");
    navigate("/login");
  };

  const onAddError = (error) => {
    console.log(error, "On get quotes Error");
    swal("Registration unsuccessful", "Please try again", "error");
  };

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gray-100">
      <div className="tw-bg-white tw-rounded-lg tw-drop-shadow-3xl tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4">
        <h2 className="tw-text-2xl tw-font-bold tw-mb-6">
          Create Your <span className="tw-text-[#537780]">MQC</span> Account
        </h2>
        <form onSubmit={handleRegister}>
          <div className="tw-mb-4">
            <input
              className={`tw-appearance-none tw-border ${
                isValidEmail
                  ? "tw-border-[#69939d] tw-focus:border-[#69939d] tw-focus:shadow-outline"
                  : "tw-border-red-500 tw-focus:border-red-500 tw-focus:shadow-outline-red"
              } tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight`}
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {!isValidEmail && (
              <p className="tw-text-red-500">
                Please enter a valid email address.
              </p>
            )}
          </div>
          <div className="tw-mb-4">
            <input
              className="tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:shadow-outline"
              id="userName"
              type="text"
              name="userName"
              placeholder="User Name"
              autoComplete="username"
              value={formData.userName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="tw-mb-4">
            <input
              className={`tw-appearance-none tw-border ${
                isValidPassword
                  ? "tw-border-[#69939d] tw-focus:shadow-outline"
                  : "tw-border-red-500 tw-focus:border-red-500 tw-focus:shadow-outline-red"
              } tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight`}
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              autoComplete="new-password"
              required
            />
            {!isValidPassword && (
              <p className="tw-text-red-500">Passwords do not match.</p>
            )}
          </div>
          <div className="tw-mb-6">
            <input
              className={`tw-appearance-none tw-border ${
                isValidPassword
                  ? "tw-border-[#69939d] tw-focus:shadow-outline"
                  : "tw-border-red-500 tw-focus:border-red-500 tw-focus:shadow-outline-red"
              } tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight`}
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              autoComplete="new-password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              required
            />
            {!isValidPassword && (
              <p className="tw-text-red-500">Passwords do not match.</p>
            )}
          </div>
          <div className="tw-flex tw-items-center tw-justify-between">
            <button
              className="tw-bg-[#69939d] hover:tw-bg-[#537780] tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-focus:outline-none focus:tw-shadow-outline tw-drop-shadow-3xl"
              type="submit"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
