import { lazy } from "react";
import Home from "./components/HomePage/Home";
import Login from "./components/User/Login";
import Register from "./components/User/Register";
import AuthorList from "./components/Quotes/QuoteLists/AuthorList";
import AuthorPage from "./components/Quotes/AuthorPage";
import StarRatings from "./components/Card/StarRatings";

const AddForms = lazy(() => import("./components/Admin/AddForms"));
const SearchPage = lazy(() => import("./components/Search/SearchPage"));
const SingleQuote = lazy(() => import("./components/Quotes/SingleQuote"));
const SavedQuotes = lazy(() => import("./components/Quotes/SavedQuotes"));
const Policy = lazy(() => import("./components/Admin/Policy"));
const TermsOfUse = lazy(() => import("./components/Admin/TermsOfUse"));
const CategoryList = lazy(() => import("./components/Categories/CategoryList"));
const CategoryPage = lazy(() => import("./components/Categories/CategoryPage"));

const AppRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/star",
    element: <StarRatings />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/3admin4",
    element: <AddForms />,
  },
  {
    path: "/quotes/search",
    element: <SearchPage />,
  },
  {
    path: "/quotes",
    element: <SingleQuote />,
  },
  {
    path: "/myquotes",
    element: <SavedQuotes />,
  },
  {
    path: "/privacy-policy",
    element: <Policy />,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUse />,
  },
  {
    path: "/authors",
    element: <AuthorList />,
  },
  {
    path: "quotes/author/:authorId",
    element: <AuthorPage />,
  },
  {
    path: "/categories",
    element: <CategoryList />,
  },
  {
    path: "/quotes/categories/Absurdity",
    element: <CategoryPage id={101} />,
  },
  {
    path: "/quotes/categories/Acting",
    element: <CategoryPage id={211} />,
  },
  {
    path: "/quotes/categories/Adventure",
    element: <CategoryPage id={53} />,
  },
  {
    path: "/quotes/categories/Aging",
    element: <CategoryPage id={224} />,
  },
  {
    path: "/quotes/categories/Agnosticism",
    element: <CategoryPage id={214} />,
  },
  {
    path: "/quotes/categories/Ambition",
    element: <CategoryPage id={177} />,
  },
  {
    path: "/quotes/categories/America",
    element: <CategoryPage id={165} />,
  },
  {
    path: "/quotes/categories/Anger",
    element: <CategoryPage id={239} />,
  },
  {
    path: "/quotes/categories/Animals",
    element: <CategoryPage id={212} />,
  },
  {
    path: "/quotes/categories/Art",
    element: <CategoryPage id={10} />,
  },
  {
    path: "/quotes/categories/Atheism",
    element: <CategoryPage id={213} />,
  },
  {
    path: "/quotes/categories/Attitude",
    element: <CategoryPage id={109} />,
  },
  {
    path: "/quotes/categories/Awareness",
    element: <CategoryPage id={92} />,
  },
  {
    path: "/quotes/categories/Balance",
    element: <CategoryPage id={144} />,
  },
  {
    path: "/quotes/categories/Beauty",
    element: <CategoryPage id={67} />,
  },
  {
    path: "/quotes/categories/Belief",
    element: <CategoryPage id={36} />,
  },
  {
    path: "/quotes/categories/Blame",
    element: <CategoryPage id={195} />,
  },
  {
    path: "/quotes/categories/Business",
    element: <CategoryPage id={257} />,
  },
  {
    path: "/quotes/categories/Change",
    element: <CategoryPage id={40} />,
  },
  {
    path: "/quotes/categories/Chaos",
    element: <CategoryPage id={253} />,
  },
  {
    path: "/quotes/categories/Character",
    element: <CategoryPage id={26} />,
  },
  {
    path: "/quotes/categories/Charity",
    element: <CategoryPage id={204} />,
  },
  {
    path: "/quotes/categories/Charm",
    element: <CategoryPage id={156} />,
  },
  {
    path: "/quotes/categories/Children",
    element: <CategoryPage id={73} />,
  },
  {
    path: "/quotes/categories/Choice",
    element: <CategoryPage id={35} />,
  },
  {
    path: "/quotes/categories/Comedy",
    element: <CategoryPage id={27} />,
  },
  {
    path: "/quotes/categories/Communication",
    element: <CategoryPage id={244} />,
  },
  {
    path: "/quotes/categories/Consciousness",
    element: <CategoryPage id={122} />,
  },
  {
    path: "/quotes/categories/Consequences",
    element: <CategoryPage id={117} />,
  },
  {
    path: "/quotes/categories/Conflict",
    element: <CategoryPage id={87} />,
  },
  {
    path: "/quotes/categories/Consumerism",
    element: <CategoryPage id={243} />,
  },
  {
    path: "/quotes/categories/Corruption",
    element: <CategoryPage id={46} />,
  },
  {
    path: "/quotes/categories/Corporations",
    element: <CategoryPage id={66} />,
  },
  {
    path: "/quotes/categories/Courage",
    element: <CategoryPage id={137} />,
  },
  {
    path: "/quotes/categories/Creativity",
    element: <CategoryPage id={123} />,
  },
  {
    path: "/quotes/categories/Crime",
    element: <CategoryPage id={146} />,
  },
  {
    path: "/quotes/categories/Criticism",
    element: <CategoryPage id={63} />,
  },
  {
    path: "/quotes/categories/Curiosity",
    element: <CategoryPage id={196} />,
  },
  {
    path: "/quotes/categories/Danger",
    element: <CategoryPage id={163} />,
  },
  {
    path: "/quotes/categories/Death",
    element: <CategoryPage id={5} />,
  },
  {
    path: "/quotes/categories/Deceit",
    element: <CategoryPage id={89} />,
  },
  {
    path: "/quotes/categories/Defeat",
    element: <CategoryPage id={61} />,
  },
  {
    path: "/quotes/categories/Depression",
    element: <CategoryPage id={20} />,
  },
  {
    path: "/quotes/categories/Desire",
    element: <CategoryPage id={150} />,
  },
  {
    path: "/quotes/categories/Destiny",
    element: <CategoryPage id={54} />,
  },
  {
    path: "/quotes/categories/Determination",
    element: <CategoryPage id={110} />,
  },
  {
    path: "/quotes/categories/Discovery",
    element: <CategoryPage id={135} />,
  },
  {
    path: "/quotes/categories/Diversity",
    element: <CategoryPage id={126} />,
  },
  {
    path: "/quotes/categories/Dogs",
    element: <CategoryPage id={164} />,
  },
  {
    path: "/quotes/categories/Doubt",
    element: <CategoryPage id={34} />,
  },
  {
    path: "/quotes/categories/Dreams",
    element: <CategoryPage id={28} />,
  },
  {
    path: "/quotes/categories/Drugs",
    element: <CategoryPage id={205} />,
  },
  {
    path: "/quotes/categories/Duty",
    element: <CategoryPage id={56} />,
  },
  {
    path: "/quotes/categories/Earth",
    element: <CategoryPage id={148} />,
  },
  {
    path: "/quotes/categories/Economics",
    element: <CategoryPage id={246} />,
  },
  {
    path: "/quotes/categories/Education",
    element: <CategoryPage id={29} />,
  },
  {
    path: "/quotes/categories/Envy",
    element: <CategoryPage id={173} />,
  },
  {
    path: "/quotes/categories/Epistemology",
    element: <CategoryPage id={241} />,
  },
  {
    path: "/quotes/categories/Equality",
    element: <CategoryPage id={247} />,
  },
  {
    path: "/quotes/categories/Ethics",
    element: <CategoryPage id={43} />,
  },
  {
    path: "/quotes/categories/Exercise",
    element: <CategoryPage id={234} />,
  },
  {
    path: "/quotes/categories/Existentialism",
    element: <CategoryPage id={86} />,
  },
  {
    path: "/quotes/categories/Experience",
    element: <CategoryPage id={119} />,
  },
  {
    path: "/quotes/categories/Evilness",
    element: <CategoryPage id={49} />,
  },
  {
    path: "/quotes/categories/Evolution",
    element: <CategoryPage id={209} />,
  },
  {
    path: "/quotes/categories/Failure",
    element: <CategoryPage id={58} />,
  },
  {
    path: "/quotes/categories/Fame",
    element: <CategoryPage id={13} />,
  },
  {
    path: "/quotes/categories/Family",
    element: <CategoryPage id={228} />,
  },
  {
    path: "/quotes/categories/Fate",
    element: <CategoryPage id={4} />,
  },
  {
    path: "/quotes/categories/Fear",
    element: <CategoryPage id={52} />,
  },
  {
    path: "/quotes/categories/Feelings",
    element: <CategoryPage id={105} />,
  },
  {
    path: "/quotes/categories/Foolishness",
    element: <CategoryPage id={252} />,
  },
  {
    path: "/quotes/categories/Forgiveness",
    element: <CategoryPage id={197} />,
  },
  {
    path: "/quotes/categories/Freedom",
    element: <CategoryPage id={76} />,
  },
  {
    path: "/quotes/categories/Friendship",
    element: <CategoryPage id={76} />,
  },
  {
    path: "/quotes/categories/Future",
    element: <CategoryPage id={41} />,
  },
  {
    path: "/quotes/categories/Generosity",
    element: <CategoryPage id={242} />,
  },
  {
    path: "/quotes/categories/Genius",
    element: <CategoryPage id={206} />,
  },
  {
    path: "/quotes/categories/Goals",
    element: <CategoryPage id={187} />,
  },
  {
    path: "/quotes/categories/God",
    element: <CategoryPage id={115} />,
  },
  {
    path: "/quotes/categories/Goodness",
    element: <CategoryPage id={50} />,
  },
  {
    path: "/quotes/categories/Government",
    element: <CategoryPage id={250} />,
  },
  {
    path: "/quotes/categories/Grace",
    element: <CategoryPage id={136} />,
  },
  {
    path: "/quotes/categories/Gratitude",
    element: <CategoryPage id={172} />,
  },
  {
    path: "/quotes/categories/Greatness",
    element: <CategoryPage id={14} />,
  },
  {
    path: "/quotes/categories/Greed",
    element: <CategoryPage id={47} />,
  },
  {
    path: "/quotes/categories/Guilt",
    element: <CategoryPage id={154} />,
  },
  {
    path: "/quotes/categories/Habits",
    element: <CategoryPage id={106} />,
  },
  {
    path: "/quotes/categories/Happiness",
    element: <CategoryPage id={55} />,
  },
  {
    path: "/quotes/categories/Hatred",
    element: <CategoryPage id={149} />,
  },
  {
    path: "/quotes/categories/Health",
    element: <CategoryPage id={225} />,
  },
  {
    path: "/quotes/categories/Heaven",
    element: <CategoryPage id={130} />,
  },
  {
    path: "/quotes/categories/Hell",
    element: <CategoryPage id={69} />,
  },
  {
    path: "/quotes/categories/Heroism",
    element: <CategoryPage id={184} />,
  },
  {
    path: "/quotes/categories/History",
    element: <CategoryPage id={15} />,
  },
  {
    path: "/quotes/categories/Home",
    element: <CategoryPage id={128} />,
  },
  {
    path: "/quotes/categories/Honesty",
    element: <CategoryPage id={208} />,
  },
  {
    path: "/quotes/categories/Honor",
    element: <CategoryPage id={37} />,
  },
  {
    path: "/quotes/categories/Hope",
    element: <CategoryPage id={38} />,
  },
  {
    path: "/quotes/categories/Humanity",
    element: <CategoryPage id={111} />,
  },
  {
    path: "/quotes/categories/Humor",
    element: <CategoryPage id={191} />,
  },
  {
    path: "/quotes/categories/Ideas",
    element: <CategoryPage id={188} />,
  },
  {
    path: "/quotes/categories/Ignorance",
    element: <CategoryPage id={141} />,
  },
  {
    path: "/quotes/categories/Illusion",
    element: <CategoryPage id={251} />,
  },
  {
    path: "/quotes/categories/Imagination",
    element: <CategoryPage id={133} />,
  },
  {
    path: "/quotes/categories/Innocence",
    element: <CategoryPage id={114} />,
  },
  {
    path: "/quotes/categories/Insanity",
    element: <CategoryPage id={210} />,
  },
  {
    path: "/quotes/categories/Inspiration",
    element: <CategoryPage id={190} />,
  },
  {
    path: "/quotes/categories/Intelligence",
    element: <CategoryPage id={48} />,
  },
  {
    path: "/quotes/categories/Investing",
    element: <CategoryPage id={245} />,
  },
  {
    path: "/quotes/categories/Journalism",
    element: <CategoryPage id={249} />,
  },
  {
    path: "/quotes/categories/Joy",
    element: <CategoryPage id={192} />,
  },
  {
    path: "/quotes/categories/Judgement",
    element: <CategoryPage id={118} />,
  },
  {
    path: "/quotes/categories/Justice",
    element: <CategoryPage id={143} />,
  },
  {
    path: "/quotes/categories/Kindness",
    element: <CategoryPage id={236} />,
  },
  {
    path: "/quotes/categories/Knowledge",
    element: <CategoryPage id={233} />,
  },
  {
    path: "/quotes/categories/Leadership",
    element: <CategoryPage id={227} />,
  },
  {
    path: "/quotes/categories/Life",
    element: <CategoryPage id={11} />,
  },
  {
    path: "/quotes/categories/Literature",
    element: <CategoryPage id={9} />,
  },
  {
    path: "/quotes/categories/Loneliness",
    element: <CategoryPage id={219} />,
  },
  {
    path: "/quotes/categories/Loss",
    element: <CategoryPage id={199} />,
  },
  {
    path: "/quotes/categories/Love",
    element: <CategoryPage id={1} />,
  },
  {
    path: "/quotes/categories/Luck",
    element: <CategoryPage id={155} />,
  },
  {
    path: "/quotes/categories/Lust",
    element: <CategoryPage id={175} />,
  },
  {
    path: "/quotes/categories/Madness",
    element: <CategoryPage id={159} />,
  },
  {
    path: "/quotes/categories/Magic",
    element: <CategoryPage id={186} />,
  },
  {
    path: "/quotes/categories/Marriage",
    element: <CategoryPage id={75} />,
  },
  {
    path: "/quotes/categories/Mathematics",
    element: <CategoryPage id={218} />,
  },
  {
    path: "/quotes/categories/Meaning",
    element: <CategoryPage id={112} />,
  },
  {
    path: "/quotes/categories/Meditation",
    element: <CategoryPage id={178} />,
  },
  {
    path: "/quotes/categories/Memories",
    element: <CategoryPage id={96} />,
  },
  {
    path: "/quotes/categories/Men",
    element: <CategoryPage id={71} />,
  },
  {
    path: "/quotes/categories/Metaphysics",
    element: <CategoryPage id={107} />,
  },
  {
    path: "/quotes/categories/Mind",
    element: <CategoryPage id={103} />,
  },
  {
    path: "/quotes/categories/Miracles",
    element: <CategoryPage id={201} />,
  },
  {
    path: "/quotes/categories/Mistakes",
    element: <CategoryPage id={57} />,
  },
  {
    path: "/quotes/categories/Monsters",
    element: <CategoryPage id={158} />,
  },
  {
    path: "/quotes/categories/Morality",
    element: <CategoryPage id={42} />,
  },
  {
    path: "/quotes/categories/Murder",
    element: <CategoryPage id={157} />,
  },
  {
    path: "/quotes/categories/Music",
    element: <CategoryPage id={78} />,
  },
  {
    path: "/quotes/categories/Mystery",
    element: <CategoryPage id={217} />,
  },
  {
    path: "/quotes/categories/Myth",
    element: <CategoryPage id={132} />,
  },
  {
    path: "/quotes/categories/Nature",
    element: <CategoryPage id={44} />,
  },
  {
    path: "/quotes/categories/Nihilism",
    element: <CategoryPage id={151} />,
  },
  {
    path: "/quotes/categories/Opportunity",
    element: <CategoryPage id={238} />,
  },
  {
    path: "/quotes/categories/Pain",
    element: <CategoryPage id={220} />,
  },
  {
    path: "/quotes/categories/Passion",
    element: <CategoryPage id={108} />,
  },
  {
    path: "/quotes/categories/Patriotism",
    element: <CategoryPage id={65} />,
  },
  {
    path: "/quotes/categories/Peace",
    element: <CategoryPage id={64} />,
  },
  {
    path: "/quotes/categories/Philosophy",
    element: <CategoryPage id={18} />,
  },
  {
    path: "/quotes/categories/Pleasure",
    element: <CategoryPage id={170} />,
  },
  {
    path: "/quotes/categories/poetry",
    element: <CategoryPage id={166} />,
  },
  {
    path: "/quotes/categories/Politics",
    element: <CategoryPage id={6} />,
  },
  {
    path: "/quotes/categories/Positivity",
    element: <CategoryPage id={232} />,
  },
  {
    path: "/quotes/categories/Poverty",
    element: <CategoryPage id={62} />,
  },
  {
    path: "/quotes/categories/Power",
    element: <CategoryPage id={33} />,
  },
  {
    path: "/quotes/categories/Problems",
    element: <CategoryPage id={51} />,
  },
  {
    path: "/quotes/categories/Psychology",
    element: <CategoryPage id={142} />,
  },
  {
    path: "/quotes/categories/Punishment",
    element: <CategoryPage id={131} />,
  },
  {
    path: "/quotes/categories/Purpose",
    element: <CategoryPage id={256} />,
  },
  {
    path: "/quotes/categories/reading",
    element: <CategoryPage id={167} />,
  },
  {
    path: "/quotes/categories/Reality",
    element: <CategoryPage id={125} />,
  },
  {
    path: "/quotes/categories/Reason",
    element: <CategoryPage id={169} />,
  },
  {
    path: "/quotes/categories/Rebellion",
    element: <CategoryPage id={91} />,
  },
  {
    path: "/quotes/categories/Relationships",
    element: <CategoryPage id={231} />,
  },
  {
    path: "/quotes/categories/Regret",
    element: <CategoryPage id={129} />,
  },
  {
    path: "/quotes/categories/Religion",
    element: <CategoryPage id={30} />,
  },
  {
    path: "/quotes/categories/Reputation",
    element: <CategoryPage id={79} />,
  },
  {
    path: "/quotes/categories/Responsibility",
    element: <CategoryPage id={162} />,
  },
  {
    path: "/quotes/categories/Risks",
    element: <CategoryPage id={193} />,
  },
  {
    path: "/quotes/categories/Science",
    element: <CategoryPage id={185} />,
  },
  {
    path: "/quotes/categories/Secrets",
    element: <CategoryPage id={121} />,
  },
  {
    path: "/quotes/categories/Self",
    element: <CategoryPage id={152} />,
  },
  {
    path: "/quotes/categories/Sex",
    element: <CategoryPage id={81} />,
  },
  {
    path: "/quotes/categories/Shame",
    element: <CategoryPage id={160} />,
  },
  {
    path: "/quotes/categories/Silence",
    element: <CategoryPage id={179} />,
  },
  {
    path: "/quotes/categories/Simpleness",
    element: <CategoryPage id={215} />,
  },
  {
    path: "/quotes/categories/Sin",
    element: <CategoryPage id={83} />,
  },
  {
    path: "/quotes/categories/Skepticism",
    element: <CategoryPage id={240} />,
  },
  {
    path: "/quotes/categories/Skill",
    element: <CategoryPage id={171} />,
  },
  {
    path: "/quotes/categories/Slavery",
    element: <CategoryPage id={168} />,
  },
  {
    path: "/quotes/categories/Society",
    element: <CategoryPage id={7} />,
  },
  {
    path: "/quotes/categories/Solitude",
    element: <CategoryPage id={182} />,
  },
  {
    path: "/quotes/categories/Soul",
    element: <CategoryPage id={82} />,
  },
  {
    path: "/quotes/categories/Spirituality",
    element: <CategoryPage id={200} />,
  },
  {
    path: "/quotes/categories/Sports",
    element: <CategoryPage id={94} />,
  },
  {
    path: "/quotes/categories/Strategy",
    element: <CategoryPage id={235} />,
  },
  {
    path: "/quotes/categories/Strength",
    element: <CategoryPage id={229} />,
  },
  {
    path: "/quotes/categories/Stupidity",
    element: <CategoryPage id={138} />,
  },
  {
    path: "/quotes/categories/Success",
    element: <CategoryPage id={194} />,
  },
  {
    path: "/quotes/categories/Sympathy",
    element: <CategoryPage id={198} />,
  },
  {
    path: "/quotes/categories/Suicide",
    element: <CategoryPage id={113} />,
  },
  {
    path: "/quotes/categories/Suffering",
    element: <CategoryPage id={25} />,
  },
  {
    path: "/quotes/categories/Symbols",
    element: <CategoryPage id={100} />,
  },
  {
    path: "/quotes/categories/Teaching",
    element: <CategoryPage id={226} />,
  },
  {
    path: "/quotes/categories/Teamwork",
    element: <CategoryPage id={248} />,
  },
  {
    path: "/quotes/categories/Time",
    element: <CategoryPage id={68} />,
  },
  {
    path: "/quotes/categories/Thought",
    element: <CategoryPage id={22} />,
  },
  {
    path: "/quotes/categories/Tragedy",
    element: <CategoryPage id={84} />,
  },
  {
    path: "/quotes/categories/Travel",
    element: <CategoryPage id={203} />,
  },
  {
    path: "/quotes/categories/Trust",
    element: <CategoryPage id={221} />,
  },
  {
    path: "/quotes/categories/Truth",
    element: <CategoryPage id={24} />,
  },
  {
    path: "/quotes/categories/Tyranny",
    element: <CategoryPage id={161} />,
  },
  {
    path: "/quotes/categories/Unity",
    element: <CategoryPage id={147} />,
  },
  {
    path: "/quotes/categories/Universe",
    element: <CategoryPage id={99} />,
  },
  {
    path: "/quotes/categories/Vice",
    element: <CategoryPage id={176} />,
  },
  {
    path: "/quotes/categories/Victory",
    element: <CategoryPage id={60} />,
  },
  {
    path: "/quotes/categories/Violence",
    element: <CategoryPage id={183} />,
  },
  {
    path: "/quotes/categories/Virtue",
    element: <CategoryPage id={74} />,
  },
  {
    path: "/quotes/categories/Void",
    element: <CategoryPage id={120} />,
  },
  {
    path: "/quotes/categories/War",
    element: <CategoryPage id={8} />,
  },
  {
    path: "/quotes/categories/Wealth",
    element: <CategoryPage id={32} />,
  },
  {
    path: "/quotes/categories/Wisdom",
    element: <CategoryPage id={3} />,
  },
  {
    path: "/quotes/categories/Women",
    element: <CategoryPage id={72} />,
  },
  {
    path: "/quotes/categories/Wonder",
    element: <CategoryPage id={216} />,
  },
  {
    path: "/quotes/categories/Work",
    element: <CategoryPage id={45} />,
  },
  {
    path: "/quotes/categories/World",
    element: <CategoryPage id={104} />,
  },
  {
    path: "/quotes/categories/Writing",
    element: <CategoryPage id={77} />,
  },
  {
    path: "/quotes/categories/Youth",
    element: <CategoryPage id={70} />,
  },
];

export default AppRoutes;
