import React, { useState } from "react";
import { useAuth } from "../Authenticaiton/Authentication";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login } = useAuth();

  const handleLogin = (event) => {
    event.preventDefault();
    let payload = { email: email, password: password };
    login(payload);
  };

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gray-100">
      <div className="tw-bg-white tw-drop-shadow-3xl tw-rounded-lg tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4">
        <h2 className="tw-text-2xl tw-font-bold tw-mb-6">
          Log Into <span className="tw-text-[#537780]">MQC</span>
        </h2>
        <form onSubmit={handleLogin}>
          <div className="tw-mb-4">
            {/* <label
              className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
              htmlFor="email"
            >
              Email
            </label> */}
            <input
              className="tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="tw-mb-6">
            {/* <label
              className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2"
              htmlFor="password"
            >
              Password
            </label> */}
            <input
              className="tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:shadow-outline"
              id="password"
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="tw-flex tw-items-center tw-justify-between">
            <button
              className="tw-bg-[#69939d] tw-drop-shadow-3xl hover:tw-bg-[#537780] tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-focus:outline-none tw-focus:shadow-outline"
              type="submit"
            >
              Log In
            </button>
            <a
              className="tw-inline-block tw-drop-shadow-3xl tw-align-baseline tw-font-bold tw-text-sm tw-text-[#69939d] hover:tw-bg-[#537780]"
              href="/forgot-password"
            >
              Forgot Password?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
