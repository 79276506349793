import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { GiAllSeeingEye } from "react-icons/gi";
import { Helmet } from "react-helmet-async";

function Error404() {
  const [navSearchQuery, setNavSearchQuery] = useState("");

  const navigate = useNavigate();

  const handleChange = (event) => {
    setNavSearchQuery(event.target.value);
  };

  const searchClick = (e) => {
    e.preventDefault();

    navigate(`/quotes/search?query=${encodeURIComponent(navSearchQuery)}`);
  };

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div
        style={{ maxWidth: "700px" }}
        className=" tw-bg-[#e7f4f7] tw-m-10 tw-mt-20 tw-rounded-2xl tw-mx-auto tw-drop-shadow-3xl"
      >
        <div className="tw-m-auto tw-max-w-[600px]">
          <div className="tw-flex tw-pt-6 tw-justify-center tw-items-center tw-mt-8">
            <GiAllSeeingEye className="tw-text-[#537780] tw-text-9xl" />
          </div>
          <div className="tw-flex tw-justify-center tw-items-center tw-mt-8">
            <h1 className="tw-text-[#537780]">
              Sorry but that page doesn't seem to exist
            </h1>
          </div>
          <div className="tw-flex tw-justify-center tw-items-center tw-mt-4">
            <form className=" tw-pb-8 tw-pt-4 tw-rounded-md tw-flex tw-justify-center tw-items-center input-bx-shadow">
              <input
                className="tw-bg-white tw-border tw-h-10 tw-border-[#69939d] tw-placeholder-[#69939d] tw-w-80 tw-rounded-md"
                type="text"
                placeholder="Search for something else instead..."
                onChange={handleChange}
                value={navSearchQuery}
              />
              <button
                onClick={searchClick}
                className="tw-bg-white tw-border tw-border-[#69939d] tw-h-10 tw-w-12 tw-rounded-md tw-transform tw-transition-transform hover:tw-scale-105"
              >
                <SearchIcon className="icon" style={{ color: "#537780" }} />
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error404;
