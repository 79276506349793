import React, { useState, useEffect } from "react";
import AuthorCard from "../../Card/AuthorCard";
import { Helmet } from "react-helmet-async";
import SearchIcon from "@mui/icons-material/Search";
import * as authorService from "../../../services/authorService";
import { BiReset } from "react-icons/bi";

function AuthorList() {
  const [searchCategoryQuery, setSearchCategoryQuery] = useState("");
  const [authorList, setAuthorList] = useState();
  const [filteredAuthors, setFilteredAuthors] = useState();
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  useEffect(() => {
    authorService
      .getAllAuthors()
      .then(onGetAuthorsSuccess)
      .catch(onGetAuthorsError);
  }, []);

  const onGetAuthorsSuccess = (response) => {
    let authorList = response.data.items;
    setAuthorList(authorList);
    setFilteredAuthors(authorList); // Add this line
  };

  const onGetAuthorsError = (error) => {
    console.log(error, "On get quotes Error");
  };

  const filterTags = (query) => {
    if (query.trim() === "") {
      setFilteredAuthors(authorList);
    } else {
      const filtered = authorList.filter((author) =>
        author.fullName.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredAuthors(filtered);
    }
  };

  const filterAuthorsByLetter = (letter) => {
    if (!letter) {
      setFilteredAuthors(authorList);
    } else {
      const filtered = authorList.filter((author) =>
        author.fullName.toLowerCase().startsWith(letter.toLowerCase())
      );
      setFilteredAuthors(filtered);
    }
  };

  const handleLetterClick = (letter) => {
    filterAuthorsByLetter(letter);
  };

  const resetFilters = () => {
    setFilteredAuthors(authorList);
    setSearchCategoryQuery("");
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="tw-w-full tw-bg-[#e7f4f7] tw-mt-14 tw-py-10 tw-rounded-2xl ">
        <div className="md:tw-max-w-[1480px] tw-m-auto tw-max-w-[600px]">
          <div className="tw-flex tw-items-center tw-justify-center">
            <h1 className="tw-text-3xl tw-font-bold tw-pb-2">
              Choose an <span className="tw-text-[#537780]">Author</span>
            </h1>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <form
              className=" tw-bg-[#87a9b1] tw-p-4 tw-shadow-lg tw-rounded-md tw-flex input-bx-shadow"
              onSubmit={(e) => {
                e.preventDefault();
                filterTags(searchCategoryQuery);
              }}
            >
              <input
                className="tw-bg-wwhite tw-border tw-border-[#69939d] tw-w-full tw-rounded-md"
                type="text"
                placeholder="Search by letter or word...."
                value={searchCategoryQuery}
                onChange={(e) => {
                  setSearchCategoryQuery(e.target.value); // Update searchQuery
                  filterTags(e.target.value); // Apply filtering
                }}
              />
              <button
                type="button"
                className=" tw-bg-white tw-border tw-border-[#69939d] tw-w-20 tw-rounded-md tw-transform tw-transition-transform hover:tw-scale-105"
              >
                <SearchIcon className="icon" style={{ color: "#537780" }} />
              </button>
            </form>
            <button
              onClick={resetFilters}
              aria-label="long-quotes"
              className={`tw-py-1 tw-px-4 tw-rounded-full tw-mr-2 tw-transition-transform tw-cursor-pointer 

              hover:tw-scale-105`}
            >
              <BiReset size={35} style={{ color: "#537780" }} />
            </button>
          </div>
          <div className="tw-mt-2 tw-flex tw-flex-wrap tw-items-center tw-justify-center">
            {alphabet.split("").map((letter) => (
              <p
                key={letter}
                id={letter}
                className="tw-transform tw-transition-transform hover:tw-scale-110 hover:tw-text-[#87a9b1]"
                onClick={() => handleLetterClick(letter)}
                style={{
                  display: "inline",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
              >
                ({letter})
              </p>
            ))}
          </div>
          <div className="tw-grid lg:tw-grid-cols-4 tw-grid-cols-2 tw-py-12 tw-px-4 tw-gap-4">
            {filteredAuthors?.map((author) => {
              return (
                <div key={author.id}>
                  <AuthorCard
                    className="tw-drop-shadow-3xl"
                    title={author.fullName}
                    id={author.id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthorList;
