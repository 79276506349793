import React from "react";
import { BsArrowUpRight } from "react-icons/bs";

function AuthorCard({ title, id }) {
  const authorLink = "/quotes/author/" + id;
  const ariaLabel = title + "-page";

  return (
    <a
      id={title}
      aria-label={ariaLabel}
      href={authorLink}
      className="hover:tw-text-[#87a9b1]"
    >
      <div className="category-card tw-flex tw-overflow-hidden tw-gap-4 tw-items-center tw-bg-white tw-p-4 tw-shadow-lg tw-rounded-md tw-justify-between tw-border tw-border-transparent hover:tw-border-[#87a9b1] hover:tw-cursor-pointer tw-group/edit tw-transform tw-transition-transform hover:tw-scale-105">
        <div className="tw-flex tw-gap-4">
          <h3 className="tw-text-2xl tw-font-semibold">{title}</h3>
        </div>
        <div className="group-hover/edit:tw-bg-[#87a9b1] tw-rounded-lg tw-p-3">
          <BsArrowUpRight
            size={25}
            style={{ color: "#87a9b1" }}
            className="arrow-icon"
          />
        </div>
      </div>
    </a>
  );
}

export default AuthorCard;
