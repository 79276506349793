import React, { useState, useEffect } from "react";
import * as quoteService from "../../services/quoteService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiBullseye } from "react-icons/gi";
import { useAuth } from "../Authenticaiton/Authentication";
import * as userService from "../../services/userService";
import swal from "sweetalert";
import { GiPolarStar } from "react-icons/gi";

function NewStarRatings({
  rating,
  ratingCount,
  ratingAggregate,
  ratedQuoteId,
}) {
  const [starRating, setStarRating] = useState({ rating: rating });
  const [hover, setHover] = useState(null);
  const [currentCount, setCurrentCount] = useState(ratingCount);
  const [currentUser, setCurrentUser] = useState({
    id: 0,
    email: "",
    userName: "",
    ratingHistory: [],
    collection: [],
  });
  const [currentAggregate, setCurrentAggregate] = useState(ratingAggregate);
  const { user } = useAuth();

  useEffect(() => {
    if (user.id) {
      userService
        .getUserById(user.id)
        .then((response) => {
          setCurrentUser(response.data.item);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user.id]);

  function changeRating(clickRating) {
    if (!user.id) {
      swal(
        "Login Required",
        "Please log in to rate quotes. This insures each user can only vote once per quote.",
        "warning"
      );
      return;
    }

    if (
      !currentUser.ratingHistory.some((quote) => quote.quoteId === ratedQuoteId)
    ) {
      let newCount = currentCount + 1;
      let newAggregate = currentAggregate + clickRating;
      let newRating = newAggregate / newCount;
      const roundedRating = Math.round(newRating);

      setStarRating({ rating: newRating });
      setCurrentCount(newCount);
      setCurrentAggregate(newAggregate);
      setCurrentUser((prevUser) => ({
        ...prevUser,
        ratingHistory: [
          ...prevUser.ratingHistory,
          { quoteId: ratedQuoteId, ratingValue: clickRating },
        ],
      }));

      let payload = {
        id: ratedQuoteId,
        rating: roundedRating,
        ratingCount: newCount,
        ratingAggregate: newAggregate,
      };

      quoteService
        .updateRating(payload)
        .then(() => onRatingUpdateSuccess(clickRating))
        .catch(onRatingUpdateError);
    } else {
      const updatedRatingHistory = user.ratingHistory.filter(
        (quote) => quote.quoteId !== ratedQuoteId
      );

      // Calculate the new aggregate  and count without the user's previous rating
      let newRatingAggregate = currentAggregate;
      let newRatingCount = currentCount;
      const removedRating = currentUser.ratingHistory.find(
        (quote) => quote.quoteId === ratedQuoteId
      );
      if (removedRating) {
        newRatingAggregate -= removedRating.ratingValue;
        newRatingCount -= 1;
        let newStarRating = newRatingAggregate / newRatingCount;
        setStarRating({ rating: newStarRating });
        setCurrentCount(newRatingCount);
        setCurrentAggregate(newRatingAggregate);
        const roundedRating = Math.round(newStarRating);

        let payload = {
          id: ratedQuoteId,
          rating: roundedRating,
          ratingCount: newRatingCount,
          ratingAggregate: newRatingAggregate,
        };

        quoteService
          .updateRating(payload)
          .then(() => onRatingRemovalSuccess(updatedRatingHistory))
          .catch(onRatingRemovalUpdateError);
      }

      setCurrentUser((prevUser) => ({
        ...prevUser,
        ratingHistory: updatedRatingHistory,
      }));
    }
  }

  const onRatingRemovalUpdateError = (error) => {
    console.log(error);
  };

  const onRatingRemovalSuccess = (updatedRatingHistory) => {
    const updatedUser = {
      ...currentUser,
      ratingHistory: updatedRatingHistory,
    };

    userService
      .updateUser(updatedUser)
      .then(onUpdateUserSuccess)
      .catch(onUpdateUserError);

    toast.success("Rating Removed!", {
      icon: (
        <div
          style={{
            fontSize: "28px",
            color: "#69939d",
            marginRight: "20px",
          }}
        >
          <GiBullseye />
        </div>
      ),
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const onRatingUpdateError = (error) => {
    console.log(error);
  };

  const onRatingUpdateSuccess = (clickRating) => {
    const updatedUser = {
      ...currentUser,
      ratingHistory: [
        ...currentUser.ratingHistory,
        { quoteId: ratedQuoteId, ratingValue: clickRating },
      ],
    };
    toast.success("Rating added!", {
      icon: (
        <div
          style={{
            fontSize: "28px",
            color: "#69939d",
            marginRight: "20px",
          }}
        >
          <GiBullseye />
        </div>
      ),
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    userService
      .updateUser(updatedUser)
      .then(onUpdateUserSuccess)
      .catch(onUpdateUserError);
  };

  const onUpdateUserSuccess = (response) => {};
  const onUpdateUserError = (error) => {
    console.log(error);
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-py-2">
      <div className="tw-flex tw-items-center">
        {[...Array(10)].map((star, index) => {
          const ratingValue = index + 1;
          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                className="tw-hidden"
                value={ratingValue}
                onClick={() => {
                  changeRating(ratingValue);
                  console.log(ratingValue);
                }}
              ></input>
              <GiPolarStar
                style={{ fontSize: "24px" }}
                color={
                  ratingValue <= (hover || starRating.rating)
                    ? "#ffc107"
                    : "e4e5e9"
                }
                className="tw-cursor-pointer"
                onMouseEnter={() => {
                  setHover(ratingValue);
                }}
                onMouseLeave={() => {
                  setHover(null);
                }}
              />
            </label>
          );
        })}
        <p className="tw-ml-1">({currentCount})</p>
      </div>
    </div>
  );
}

export default NewStarRatings;
