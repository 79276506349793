import axios from "axios";

const API_HOST_PREFIX = process.env.REACT_APP_API_HOST_PREFIX;

const endpoint = `${API_HOST_PREFIX}/api/quotes`;

const apiKey = process.env.REACT_APP_API_KEY;
const headers = {
  "Content-Type": "application/json",
  ApiKey: apiKey,
};

const getAllQuotes = () => {
  const config = {
    method: "GET",
    url: `${endpoint}/`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const getById = (id) => {
  const config = {
    method: "GET",
    url: `${endpoint}/${id}`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const getTop30 = () => {
  const config = {
    method: "GET",
    url: `${endpoint}/top30`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const getAllQuotesByTopic = (id) => {
  const config = {
    method: "GET",
    url: `${endpoint}/category/${id}`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};
const getAllQuotesByAuthor = (id) => {
  const config = {
    method: "GET",
    url: `${endpoint}/author/${id}`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const addQuote = (payload) => {
  const config = {
    method: "POST",
    url: `${endpoint}/`,
    data: payload,
    crossdomain: true,
    headers: headers,
  };

  return axios(config);
};

const updateRating = (payload) => {
  const config = {
    method: "PUT",
    url: `${endpoint}/rating`,
    data: payload,
    crossdomain: true,
    headers: headers,
  };

  return axios(config);
};

export {
  getAllQuotes,
  getAllQuotesByTopic,
  addQuote,
  getTop30,
  getById,
  updateRating,
  getAllQuotesByAuthor,
};
