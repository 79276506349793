import React, { Component, lazy } from "react";
import { Container } from "reactstrap";
import Navbar from "./Navbar";
const Footer = lazy(() => import("./Footer"));

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <Navbar />
        <Container tag="main">{this.props.children}</Container>
        <Footer />
      </div>
    );
  }
}
