import React, { useState } from "react";
// import logo from "../assets/NavLogo.png";
import LockOpenIcon from "@mui/icons-material/LockOpenTwoTone";
import MenuIcon from "@mui/icons-material/MenuTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./Authenticaiton/Authentication";
import { HiHomeModern } from "react-icons/hi2";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import AsyncImageLoader from "./Admin/AsyncImageLoader";

function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [navSearchQuery, setNavSearchQuery] = useState("");
  const { loggedIn, logout } = useAuth();
  const navbarClasses = `tw-w-full tw-h-[59px] tw-w-[175px] tw-bg-gray-200 tw-border-b tw-overflow-hidden ${
    toggle ? "" : " tw-z-50 tw-fixed  tw-top-0 tw-left-0 "
  }`;
  // const imageStyles =
  //   "tw-rounded-2xl tw-h-[75px] tw-w-[122px] tw-pl-1 tw-py-3 tw-transform tw-transition-transform hover:tw-scale-105";

  const navigate = useNavigate();

  const handleChange = (event) => {
    setNavSearchQuery(event.target.value);
  };

  const handleClick = () => {
    setToggle(!toggle);
  };
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional: adds smooth scrolling animation
  });

  const searchClick = (e) => {
    e.preventDefault();

    navigate(`/quotes/search?query=${encodeURIComponent(navSearchQuery)}`);
  };

  const loginClick = (e) => {
    e.preventDefault();
    setToggle(false);
    navigate(`/login`);
  };

  const logoutClick = (e) => {
    e.preventDefault();
    logout();
  };
  const registerClick = (e) => {
    e.preventDefault();
    setToggle(false);
    navigate(`/register`);
  };

  return (
    <div className={navbarClasses}>
      <div className="md:tw-max-w-[1480px] tw-max-w-[600px] tw-m-auto tw-w-full tw-h-full tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center">
          {/* <a href="/" aria-label="home">
            <AsyncImageLoader
              imageUrl={logo}
              styles={imageStyles}
              altText={"Funny Quotes about life"}
            />
          </a> */}
          <a
            href="/"
            aria-label="home"
            className="hover:tw-text-[#87a9b1] tw-ml-4 tw-transform tw-transition-transform hover:tw-scale-110  tw-text-xl"
          >
            <p className="tw-text-2xl">
              <HiHomeModern />
            </p>
          </a>
          <div className="tw-hidden tw-pl-4 md:tw-flex tw-items-center tw-justify-center">
            <div className="tw-flex tw-gap-4 tw-list-none">
              <a
                href="/categories"
                aria-label="categories"
                className="hover:tw-text-[#87a9b1] tw-transform tw-transition-transform hover:tw-scale-110 "
              >
                <p>
                  Categories <KeyboardArrowDownIcon />
                </p>
              </a>
              <a
                href="/authors"
                aria-label="authors"
                className="hover:tw-text-[#87a9b1] tw-transform tw-transition-transform hover:tw-scale-110 "
              >
                <p>
                  Authors <KeyboardArrowDownIcon />
                </p>
              </a>
              <a
                href="/myquotes"
                aria-label="my quotes"
                className="hover:tw-text-[#87a9b1] tw-transform tw-transition-transform hover:tw-scale-110 "
              >
                <p>
                  My Quotes <KeyboardArrowDownIcon />
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-justify-end tw-items-center">
          {window.location.href.includes("/quotes/search?query=") ? null : (
            <form className="tw-p-4 tw-rounded-md tw-flex tw-justify-center tw-items-end input-bx-shadow">
              <input
                className="tw-bg-white tw-border tw-h-10 tw-border-[#69939d] tw-placeholder-[#69939d] tw-w-80 tw-rounded-md tw-hidden lg:tw-block"
                type="text"
                placeholder="Search for anything..."
                onChange={handleChange}
                value={navSearchQuery}
              />
              <button
                onClick={searchClick}
                aria-label="Search"
                className="tw-bg-white tw-border tw-border-[#69939d] tw-h-10 tw-w-12 tw-rounded-md tw-transform tw-transition-transform hover:tw-scale-105"
              >
                <SearchIcon className="icon" style={{ color: "#537780" }} />
              </button>
            </form>
          )}
          <div className="tw-hidden md:tw-flex">
            {!loggedIn ? (
              <>
                <button
                  type="button"
                  aria-label="Login"
                  onClick={loginClick}
                  className="tw-bg-[#87a9b1] tw-text-white tw-px-6 tw-pt-1 tw-rounded-md tw-items-center tw-justify-between tw-font-bold tw-mr-3 tw-transform tw-transition-transform hover:tw-scale-110"
                >
                  <LockOpenIcon className="tw-pr-2" fontSize="large" />
                  Login
                </button>
                <button
                  type="button"
                  aria-label="Sign Up For Free"
                  onClick={registerClick}
                  className="tw-bg-[#537780] tw-rounded-md tw-text-white tw-px-4 tw-py-3 tw-font-bold tw-pr-4 tw-transform tw-transition-transform hover:tw-scale-110"
                >
                  Sign up for Free
                </button>{" "}
              </>
            ) : (
              <button
                type="button"
                aria-label="Logout"
                onClick={logoutClick}
                className="tw-bg-[#87a9b1] tw-text-white tw-px-6 tw-rounded-md tw-items-center tw-justify-between tw-font-bold tw-mr-3 tw-transform tw-transition-transform hover:tw-scale-110"
              >
                <LockOpenIcon className="tw-pr-2" fontSize="large" />
                Logout
              </button>
            )}
          </div>
          <div
            className="tw-pr-8 md:tw-hidden hover:tw-cursor-pointer"
            onClick={handleClick}
          >
            {toggle ? (
              <div>
                {" "}
                <CloseIcon />{" "}
              </div>
            ) : (
              <div>
                {" "}
                <MenuIcon />{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          toggle
            ? "tw-absolute tw-z-10 tw-p-4 tw-w-full tw-px-8 tw-bg-[#e6e6e6] md:tw-hidden"
            : "tw-hidden"
        }
      >
        <a
          href="/"
          className="hover:tw-text-[#87a9b1] tw-transform tw-transition-transform hover:tw-scale-110  text-xl"
        >
          <p className="tw-p-4 tw-text-xl  hover:tw-bg-white">Home</p>
        </a>
        <a
          href="/categories"
          className="hover:tw-text-[#87a9b1] tw-transform tw-transition-transform hover:tw-scale-110  tw-text-xl"
        >
          <p className="tw-p-4 tw-text-xl  hover:tw-bg-white">Categories</p>
        </a>
        <a
          href="/authors"
          aria-label="authors"
          className="hover:tw-text-[#87a9b1] tw-transform tw-transition-transform hover:tw-scale-110 "
        >
          <p className="tw-p-4 tw-text-xl  hover:tw-bg-white">Authors</p>
        </a>
        <a
          href="/myquotes"
          className="hover:tw-text-[#87a9b1] tw-transform tw-transition-transform hover:tw-scale-110  tw-text-xl"
        >
          <p className="tw-p-4 tw-text-xl  hover:tw-bg-white">My Quotes</p>
        </a>
        <div className="tw-flex tw-flex-col tw-my-4 tw-gap-4">
          {!loggedIn ? (
            <>
              <button
                type="button"
                aria-label="Login"
                onClick={loginClick}
                className="tw-bg-[#87a9b1] tw-text-white tw-px-6 tw-rounded-md tw-items-center tw-justify-between tw-font-bold tw-mr-3 tw-transform tw-transition-transform hover:tw-scale-110"
              >
                <LockOpenIcon className="tw-pr-2" fontSize="large" />
                Login
              </button>
              <button
                type="button"
                aria-label="Register"
                onClick={registerClick}
                className="tw-bg-[#537780] tw-rounded-md tw-text-white tw-px-4 tw-py-3 tw-font-bold tw-pr-4 tw-transform tw-transition-transform hover:tw-scale-110"
              >
                Sign up for Free
              </button>{" "}
            </>
          ) : (
            <button
              type="button"
              aria-label="Logout"
              onClick={logoutClick}
              className="tw-bg-[#87a9b1] tw-text-white tw-px-6 tw-rounded-md tw-items-center tw-justify-between tw-font-bold tw-mr-3 tw-transform tw-transition-transform hover:tw-scale-110"
            >
              <LockOpenIcon className="tw-pr-2" fontSize="large" />
              Logout
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
