import axios from "axios";

const API_HOST_PREFIX = process.env.REACT_APP_API_HOST_PREFIX;

const endpoint = `${API_HOST_PREFIX}/api/authors`;

const apiKey = process.env.REACT_APP_API_KEY;
const headers = {
  "Content-Type": "application/json",
  ApiKey: apiKey,
};

const getAllAuthors = () => {
  const config = {
    method: "GET",
    url: `${endpoint}/`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const getAuthorById = (id) => {
  const config = {
    method: "GET",
    url: `${endpoint}/${id}`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

export { getAllAuthors, getAuthorById };
