import axios from "axios";

const API_HOST_PREFIX = process.env.REACT_APP_API_HOST_PREFIX;

const endpoint = `${API_HOST_PREFIX}/api/users`;

const apiKey = process.env.REACT_APP_API_KEY;
const headers = {
  "Content-Type": "application/json",
  ApiKey: apiKey,
};

const addUser = (payload) => {
  const config = {
    method: "POST",
    url: `${endpoint}/`,
    data: payload,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const updateUser = (payload) => {
  const config = {
    method: "PUT",
    url: `${endpoint}/`,
    data: payload,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const loginUser = (payload) => {
  const config = {
    method: "POST",
    url: `${endpoint}/login`,
    data: payload,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const logoutUser = () => {
  const config = {
    method: "POST",
    url: `${endpoint}/logout`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const getCurrent = (payload) => {
  const config = {
    method: "GET",
    url: `${endpoint}/current`,
    data: payload,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

const getUserById = (id) => {
  const config = {
    method: "GET",
    url: `${endpoint}/${id}`,
    crossdomain: true,
    headers: headers,
  };
  return axios(config);
};

export { addUser, loginUser, getCurrent, logoutUser, getUserById, updateUser };
