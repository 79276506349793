import React, { useState, useEffect, Suspense, lazy } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import * as quoteService from "../../services/quoteService";
import { useAuth } from "../Authenticaiton/Authentication";
import * as userService from "../../services/userService";
import { GiBullseye } from "react-icons/gi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DailyCard = lazy(() => import("../Card/DailyCard"));
const Slider = lazy(() => import("react-slick"));

const Hero = () => {
  const [pageData, setPageData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentUser, setCurrentUser] = useState({
    id: 0,
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    collection: [
      {
        id: 6,
        quoteContent:
          "Welcome to MyQuoteCollection! Click more hearts to add to your collection.",
        authorId: 1,
        sourceId: 17,
        rating: 10,
        ratingAggregate: 10,
        ratingCount: 1,
        fullName: "Welcome Bot",
        tags: [{ id: 11, name: "Love" }],
      },
    ],
    ratingHistory: [{ QuoteId: 6, RatingValue: 10 }],
  });
  const { user } = useAuth();

  useEffect(() => {
    if (user.id) {
      userService
        .getUserById(user.id)
        .then((response) => {
          setCurrentUser(response?.data?.item);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user.id]);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    quoteService.getTop30().then(onGetQuotesSuccess).catch(onGetQuotesError);
    // eslint-disable-next-line
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const onGetQuotesSuccess = (response) => {
    let newQuotes = response.data.items;
    let filteredQuotes = newQuotes.filter(
      (quote) => quote.quoteContent.split(" ").length <= 40
    );
    let shuffledQuotes = shuffleArray(filteredQuotes);
    let firstTenShuffledQuotes = shuffledQuotes.slice(0, 5);
    setPageData(firstTenShuffledQuotes);
  };

  const onGetQuotesError = (error) => {
    console.log(error, "On get quotes Error");
  };

  const addToFavorites = async (quote) => {
    if (!currentUser.collection.some((fav) => fav.id === quote.id)) {
      const updatedUser = {
        ...currentUser,
        collection: [...currentUser.collection, quote],
      };
      setCurrentUser(updatedUser); // Update the state first

      try {
        await userService.updateUser(updatedUser); // Update user data on server
        toast.success("Favorite Added!", {
          icon: (
            <div
              style={{
                fontSize: "28px",
                color: "#69939d",
                marginRight: "20px",
              }}
            >
              <GiBullseye />
            </div>
          ),
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeFromFavorites = async (quoteId) => {
    const updatedCollection = currentUser.collection.filter(
      (collectionQuote) => quoteId !== collectionQuote.id
    );

    const updatedUser = {
      ...currentUser,
      collection: updatedCollection,
    };

    try {
      await userService.updateUser(updatedUser); // Update user data on server
      setCurrentUser(updatedUser); // Update the state
      onUpdateUserSuccess();

      toast.success("Favorite Removed!", {
        icon: (
          <div
            style={{
              fontSize: "28px",
              color: "#69939d",
              marginRight: "20px",
            }}
          >
            <GiBullseye />
          </div>
        ),
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      onUpdateUserError(error);
    }
  };

  const onUpdateUserSuccess = (response) => {};
  const onUpdateUserError = (error) => {
    console.log(error);
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim() !== "") {
      navigate(`/quotes/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  const handleButtonSubmit = () => {
    navigate(`/quotes/search?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <div className="tw-w-full tw-bg-gray-100 tw-pt-20 tw-pl-4">
      <div className="md:tw-max-w-[1280px] tw-m-auto tw-grid md:tw-grid-cols-2 tw-max-w-[600px]">
        <div className="">
          <p className="tw-py-2 tw-text-2xl tw-text-[#69939d] tw-font-medium">
            Welcome to MyQuoteCollection.com
          </p>
          <h1 className="md:tw-text-6xl tw-font-semibold tw-py-3">
            Browse through <span className="tw-text-[#537780]">thousands</span>{" "}
            of the best cited inspirational quotes!
          </h1>
          <p className="custom-mt-4 tw-py-2 tw-mt-4 tw-text-lg tw-text-[#949191]">
            {" "}
            We've sifted through pages and pages of motivational and
            inspirational quotes and linked them so that you can not only
            discover the authors who wrote them, but get instant access to the
            whole story or stick around here and build your own collection of
            motivational quotes.
            <br />
            <br />
            New quotes are added daily, so check back often!
            <br />
            <br />
          </p>
          <form
            className="tw-max-w-[700px] tw-p-10 tw-shadow-lg tw-rounded-md tw-flex tw-justify-between input-bx-shadow"
            onSubmit={handleSubmit}
          >
            <input
              className=" tw-bg-wwhite tw-border tw-border-[#69939d] tw-w-full tw-rounded-md"
              type="text"
              placeholder="What do you find inspiring?"
              value={searchQuery}
              onChange={handleChange}
            />
            <button
              type="button"
              aria-label="Search"
              onClick={handleButtonSubmit}
              className=" tw-bg-white tw-border tw-border-[#69939d] tw-w-20 tw-rounded-md tw-transform tw-transition-transform hover:tw-scale-110"
            >
              <SearchIcon className="icon" style={{ color: "#537780" }} />
            </button>
          </form>
        </div>
        <div className=" tw-pt-2 tw-rounded-2xl tw-pl-2">
          <div className="">
            <div className="tw-flex tw-items-center tw-justify-center">
              <h2 className="tw-text-3xl tw-font-bold ">
                Quotes of the <span className="tw-text-[#537780]">Day</span>
              </h2>
            </div>
            <div className="slider-wrapper">
              <Suspense
                fallback={
                  <div className="loading-spinner">
                    <PulseLoader color="#537780" size={15} />
                  </div>
                }
              >
                <Slider className="" {...settings}>
                  {pageData?.map((quote) => (
                    <Suspense
                      key={quote.id}
                      fallback={
                        <div className="loading-spinner">
                          <PulseLoader color="#537780" size={15} />
                        </div>
                      }
                    >
                      <DailyCard
                        quote={quote}
                        currentUser={currentUser}
                        addToFavorites={addToFavorites}
                        removeFromFavorites={removeFromFavorites}
                      />
                    </Suspense>
                  ))}
                </Slider>
              </Suspense>
              <div className="tw-flex tw-items-center tw-justify-center">
                <p className="tw-pb-2 tw-text-slate-500 tw-pl-2">
                  If you're registered and logged in click a star to cast your
                  own rating or a heart to save the quote in your own personal
                  collection or click on the dialogue icon to comment on the
                  quote with other users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
