import { createTheme } from "@mui/material";

const theme = createTheme({
  status: {},
  palette: {
    primary: {
      main: "#69939d",
      light: "#87a9b1",
      dark: "#537780",
    },
    secondary: {
      main: "#e6e6e6",
      light: "#ffffff",
      lightBlue: "#e7f4f7",
    },
    action: {
      main: "#f76b8a",
    },
  },
});

export default theme;
