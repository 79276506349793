import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import "tailwindcss/tailwind.css";
import { AuthProvider } from "./components/Authenticaiton/Authentication";
import Error404 from "./components/ErrorPage/Error404";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";
import { PulseLoader } from "react-spinners";

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <ThemeProvider theme={createTheme(theme)}>
          <Layout>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { path, element } = route;
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <Suspense
                        fallback={
                          <div className="loading-spinner">
                            <PulseLoader color="#537780" size={15} />
                          </div>
                        }
                      >
                        {element}
                      </Suspense>
                    }
                  />
                );
              })}
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Layout>
        </ThemeProvider>
        <ToastContainer />
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
