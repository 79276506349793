import React, { createContext, useContext, useState, useEffect } from "react";
import * as userService from "../../services/userService";
import swal from "sweetalert";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({
    id: 0,
    email: "",
    userName: "",
    ratingHistory: [],
    collection: [],
  });

  useEffect(() => {
    // Check authentication status on component mount
    userService
      .getCurrent()
      .then((response) => {
        if (response.data.item == null) {
          setLoggedIn(false);
        } else {
          setLoggedIn(true);
          userService
            .getUserById(response.data.item.id)
            .then(onGetUserByIdSuccess)
            .catch(onGetUserByIdError);
        }
      })
      .catch((error) => {
        setLoggedIn(false);
      });
  }, []);

  const login = (payload) => {
    userService.loginUser(payload).then(onLoginSuccess).catch(onLoginError);
  };

  const onLoginError = (error) => {
    console.log(error);
    swal("Login unsuccessful", "Please try again", "error");
  };

  const onLoginSuccess = (response) => {
    userService.getCurrent().then(onGetCurrentSuccess).catch(onGetCurrentError);
  };

  const onGetCurrentSuccess = (response) => {
    setLoggedIn(true);

    // const stateForTransport = { type: "USER_LOGIN", payload: response.item };
    window.location.href = "/";
    swal({
      title: "Login successful!",
      icon: "success",
    });
  };
  const onGetUserByIdSuccess = (response) => {
    setUser(response.data.item);
  };
  const onGetUserByIdError = (error) => {
    console.log(error);
  };
  const onGetCurrentError = (error) => {
    console.log(error);
  };
  const logout = () => {
    userService.logoutUser().then(onLogoutSuccess).catch(onLogoutError);
  };

  const onLogoutError = (error) => {
    console.log(error);
    swal("Logout unsuccessful", "Please try again", "error");
  };

  const onLogoutSuccess = (response) => {
    swal({
      title: "Logout successful! Please visit again soon!",
      icon: "success",
    });

    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ loggedIn, user, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
