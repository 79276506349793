import React, { lazy, Suspense } from "react";
import Hero from "./Hero";
import { PulseLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const CategoriesLazy = lazy(() => import("./Categories"));
// const TopRated = lazy(() => import("./TopRated"));

function Home() {
  const metaData = {
    structuredData: {
      "@context": "https://schema.org",
      "@type": "ItemList",
      name: "MyQuoteCollection Homepage",
      description: "Discover the best motivational and inspirational quotes.",
      url: "https://www.myquotecollection.com/",
      numberOfItems: 10,
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          url: "https://www.myquotecollection.com/quotes/categories/Love",
        },
        {
          "@type": "ListItem",
          position: 2,
          url: "https://www.myquotecollection.com/quotes/categories/Life",
        },
        {
          "@type": "ListItem",
          position: 3,
          url: "https://www.myquotecollection.com/quotes/categories/Comedy",
        },
        {
          "@type": "ListItem",
          position: 4,
          url: "https://www.myquotecollection.com/quotes/categories/Friendship",
        },
        {
          "@type": "ListItem",
          position: 5,
          url: "https://www.myquotecollection.com/quotes/categories/Change",
        },
        {
          "@type": "ListItem",
          position: 6,
          url: "https://www.myquotecollection.com/quotes/categories/Depression",
        },
        {
          "@type": "ListItem",
          position: 7,
          url: "https://www.myquotecollection.com/quotes/categories/Death",
        },
        {
          "@type": "ListItem",
          position: 8,
          url: "https://www.myquotecollection.com/quotes/categories/Suffering",
        },
        {
          "@type": "ListItem",
          position: 9,
          url: "https://www.myquotecollection.com/quotes/categories/Greatness",
        },
        {
          "@type": "ListItem",
          position: 10,
          url: "https://www.myquotecollection.com/quotes/categories/Philosophy",
        },
      ],
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          MyQuoteCollection - The Best Motivational & Inspirational Quotes
        </title>
        <meta
          name="description"
          content="Discover the best motivational & inspirational quotes. Explore cited famous & daily quotes, add comments, rate and build your own collection."
        />
        <link rel="canonical" href="https://www.MyQuoteCollection.com/" />
        <meta
          name="keywords"
          content="inspirational quotes, motivational quotes, quote of the day, love quotes"
        />
        <meta property="og:url" content="https://www.MyQuoteCollection.com/" />
        <meta property="og:type" content={"summary_large_image"} />
        <meta
          property="og:title"
          content="MyQuoteCollection - Motivational & Inspirational Quotes"
        />
        <meta
          property="og:description"
          content="Discover the best motivational & inspirational quotes. Explore cited famous & daily quotes, add comments, rate and build your own collection."
        />
        <meta
          name="twitter:creator"
          content="https://www.MyQuoteCollection.com"
        />
        <meta name="twitter:card" content={"summary_large_image"} />
        <meta
          name="twitter:title"
          content=" MyQuoteCollection - The Best Motivational & Inspirational Quotes"
        />
        <meta
          name="twitter:description"
          content="Discover the best motivational & inspirational quotes. Explore cited famous & daily quotes, add comments, rate and build your own collection."
        />
        <script type="application/ld+json">
          {JSON.stringify(metaData?.structuredData)}
        </script>
      </Helmet>
      <div>
        <Hero />
      </div>
      <div>
        <Suspense
          fallback={
            <div className="loading-spinner">
              <PulseLoader color="#537780" size={15} />
            </div>
          }
        >
          {/* <TopRated /> */}
          <CategoriesLazy />
        </Suspense>
      </div>
    </div>
  );
}

export default Home;
