import React, { useEffect, useState, Suspense, lazy } from "react";
import * as quoteService from "../../services/quoteService";
import * as authorService from "../../services/authorService";
import Slider from "react-slick";
import SearchIcon from "@mui/icons-material/Search";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useAuth } from "../Authenticaiton/Authentication";
import { Helmet } from "react-helmet-async";
import { PulseLoader } from "react-spinners";
import { BiReset } from "react-icons/bi";
import * as userService from "../../services/userService";
import { GiBullseye } from "react-icons/gi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
const Card = lazy(() => import("../Card/AuthorQuoteCard"));
const SliderCard = lazy(() => import("../Card/AuthorSliderCard"));

function AuthorPage() {
  const [pageData, setPageData] = useState();
  const [filteredPageData, setFilteredPageData] = useState();
  const [authorName, setAuthorName] = useState();
  const [metaData, setMetaData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [isGridView, setIsGridView] = useState(true);
  const [filterType, setFilterType] = useState("");
  const [infiniteScrollData, setInfiniteScrollData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [currentUser, setCurrentUser] = useState({
    id: 0,
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    collection: [
      {
        id: 6,
        quoteContent:
          "Welcome to MyQuoteCollection! Click more hearts to add to your collection.",
        authorId: 1,
        sourceId: 17,
        rating: 10,
        ratingAggregate: 10,
        ratingCount: 1,
        fullName: "Welcome Bot",
        tags: [{ id: 11, name: "Love" }],
      },
    ],
    ratingHistory: [{ QuoteId: 6, RatingValue: 10 }],
  });
  const { user } = useAuth();
  const params = useParams();
  const id = params.authorId;

  function shortenString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
      return inputString;
    }

    const shortened = inputString.substr(0, maxLength);
    return shortened
      .substr(0, Math.min(shortened.length, shortened.lastIndexOf(" ")))
      .trim();
  }

  useEffect(() => {
    quoteService
      .getAllQuotesByAuthor(id)
      .then(onGetAuthorQuotesSuccess)
      .catch(onGetAuthorQuotesError);
  }, [id]);

  useEffect(() => {
    authorService
      .getAuthorById(id)
      .then((response) => {
        setAuthorName(response?.data?.item?.fullName);
        const shortenedTitle = shortenString(response?.data?.item?.title, 69);
        const shortenedDescription = shortenString(
          response?.data?.item?.description,
          150
        );

        const newMetaData = {
          canonicalUrl: response?.data?.item?.canonicalUrl,
          description: shortenedDescription,
          keywords: response?.data?.item?.keywords,
          title: shortenedTitle,
          structuredData: {
            "@context": "https://schema.org",
            "@type": "ItemList",
            url: response?.data?.item?.canonicalUrl,
            numberOfItems: 9,
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                url: response?.data?.item?.structuredData?.itemListElement[0]
                  .url,
                item: {
                  "@type": "Quote",
                  name: response?.data?.item?.fullName,
                  // text: pageData[0].quoteContent,
                },
              },
              // {
              //   "@type": "ListItem",
              //   position: 2,
              //   url: response.data.item.structuredData.itemListElement[1].url,
              //   item: {
              //     "@type": "Quote",
              //     name: response.data.item.structuredData.itemListElement[1].name,
              //     text: response.data.item.structuredData.itemListElement[1].text,
              //   },
              // },
            ],
          },
        };

        setMetaData(newMetaData);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (user.id) {
      userService
        .getUserById(user.id)
        .then((response) => {
          setCurrentUser(response?.data?.item);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user.id]);

  const placeholder = "Search quotes about " + authorName + " for...";

  const onGetAuthorQuotesSuccess = (response) => {
    let newQuotes = response.data.items;
    setPageData(newQuotes);
    setFilteredPageData(newQuotes);
    if (newQuotes.length < 50) {
      setInfiniteScrollData(newQuotes);
    } else {
      setInfiniteScrollData(newQuotes.slice(0, 50));
    }
  };

  const onGetAuthorQuotesError = (error) => {};

  function filterArrayBySearchWord(e) {
    e.preventDefault();
    const inputValue = e.target.value.toLowerCase();

    const filteredArray = pageData.filter(
      (item) =>
        item?.quoteContent?.toLowerCase().includes(inputValue) ||
        item?.fullName?.toLowerCase().includes(inputValue) ||
        item?.title?.toLowerCase().includes(inputValue)
    );

    setFilteredPageData(filteredArray);
    setInfiniteScrollData(filteredArray);
    setSearchQuery(inputValue); // Update the search query state
  }

  const filterQuotes = (type) => {
    const filteredQuotes = pageData.filter((quote) => {
      const wordCount = quote?.quoteContent.split(" ").length;
      if (type === "short") {
        return wordCount <= 30;
      } else if (type === "long") {
        return wordCount > 30;
      }
      return true; // No filter selected, show all quotes
    });

    setFilteredPageData(filteredQuotes);
    setInfiniteScrollData(filteredQuotes);
    setFilterType(type); // Update the active filter type
  };

  const resetFilters = () => {
    setFilteredPageData(pageData);
    setInfiniteScrollData(pageData);
    setFilterType("");
    setSearchQuery("");
  };

  const loadMore = () => {
    if (filteredPageData) {
      const additionalItems = filteredPageData.slice(
        infiniteScrollData.length,
        infiniteScrollData.length + 36
      );

      setInfiniteScrollData((prevData) => [...prevData, ...additionalItems]);
      setIsLoadingMore(false);
      setHasMoreItems(infiniteScrollData.length + 36 < filteredPageData.length);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoadingMore, // Set to true when loading more items
    hasNextPage: true, // Set to false when no more items to load
    onLoadMore: loadMore,
    // Other options like disabled, rootMargin, etc.
  });

  const addToFavorites = async (quote) => {
    if (!currentUser.collection.some((fav) => fav.id === quote.id)) {
      const updatedUser = {
        ...currentUser,
        collection: [...currentUser.collection, quote],
      };
      setCurrentUser(updatedUser); // Update the state first

      try {
        await userService.updateUser(updatedUser); // Update user data on server
        toast.success("Favorite Added!", {
          icon: (
            <div
              style={{
                fontSize: "28px",
                color: "#69939d",
                marginRight: "20px",
              }}
            >
              <GiBullseye />
            </div>
          ),
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeFromFavorites = async (quoteId) => {
    const updatedCollection = currentUser.collection.filter(
      (collectionQuote) => quoteId !== collectionQuote.id
    );

    const updatedUser = {
      ...currentUser,
      collection: updatedCollection,
    };

    try {
      await userService.updateUser(updatedUser); // Update user data on server
      setCurrentUser(updatedUser); // Update the state
      onUpdateUserSuccess();

      toast.success("Favorite Removed!", {
        icon: (
          <div
            style={{
              fontSize: "28px",
              color: "#69939d",
              marginRight: "20px",
            }}
          >
            <GiBullseye />
          </div>
        ),
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      onUpdateUserError(error);
    }
  };

  const onUpdateUserSuccess = (response) => {};
  const onUpdateUserError = (error) => {
    console.log(error);
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          arrows: true,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  if (filteredPageData?.length === 1 || filteredPageData?.length === 2) {
    settings.slidesToShow = 1;
    settings.slidesToScroll = 1;
  }

  return (
    <>
      {metaData && (
        <Helmet>
          <title>{metaData?.title}</title>
          <meta name="description" content={metaData?.description} />
          <link rel="canonical" href={metaData?.canonicalUrl} />
          <meta name="keywords" content={metaData?.keywords} />
          <meta property="og:url" content={metaData?.canonicalUrl} />
          <meta property="og:type" content={"summary_large_image"} />
          <meta property="og:title" content={metaData?.title} />
          <meta property="og:description" content={metaData?.description} />
          <meta
            name="twitter:creator"
            content="https://www.MyQuoteCollection.com"
          />
          <meta name="twitter:card" content={"summary_large_image"} />
          <meta name="twitter:title" content={metaData?.title} />
          <meta name="twitter:description" content={metaData?.description} />
          <script type="application/ld+json">
            {JSON.stringify(metaData?.structuredData)}
          </script>
        </Helmet>
      )}
      <div className="tw-w-full tw-bg-[#e7f4f7] tw-pt-28 tw-py-10 tw-rounded-2xl tw-flex tw-items-center tw-justify-center">
        <div className="md:tw-max-w-[1480px] tw-m-auto tw-px-2 ">
          <div className="tw-flex tw-items-center tw-justify-center">
            <h1 className="tw-text-3xl tw-font-bold tw-pb-2">
              Inspirational Quotes from{" "}
              <span className="tw-text-[#537780]">{authorName}</span>
            </h1>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <p className="tw-pb-2 tw-text-slate-500">
              Click a star to cast your own vote or a heart if you're registered
              and logged in to save the quote in your own personal collection.
            </p>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <form
              className=" tw-bg-[#87a9b1] tw-p-4 tw-shadow-lg tw-rounded-md tw-flex input-bx-shadow"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="tw-bg-wwhite tw-border tw-border-[#69939d] tw-w-full tw-rounded-md"
                type="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={filterArrayBySearchWord}
              />
              <button
                type="button"
                aria-label="search"
                className=" tw-bg-white tw-border tw-border-[#69939d] tw-w-20 tw-rounded-md tw-transform tw-transition-transform hover:tw-scale-105"
              >
                <SearchIcon className="icon" style={{ color: "#537780" }} />
              </button>
            </form>
          </div>
          <div className="tw-pt-2">
            <div className="tw-flex tw-items-center tw-justify-center">
              <ViewCarouselIcon
                aria-label="slider-view"
                className={` tw-mr-3 tw-transform tw-transition-transform hover:tw-scale-110 tw-cursor-pointer ${
                  isGridView ? "tw-opacity-50" : ""
                }`}
                onClick={() => setIsGridView(false)}
              />
              <ViewModuleIcon
                aria-label="grid-view"
                className={` tw-transform tw-transition-transform hover:tw-scale-110 tw-cursor-pointer ${
                  isGridView ? "" : "tw-opacity-50"
                }`}
                onClick={() => setIsGridView(true)}
              />
              <button
                aria-label="short quotes"
                onClick={() => filterQuotes("short")}
                className={`tw-ml-2 before:tw-py-1 tw-px-4 tw-rounded-full tw-mr-2 tw-transition-transform tw-cursor-pointer ${
                  filterType === "short"
                    ? "tw-bg-black tw-text-white"
                    : "tw-bg-gray-300"
                } hover:tw-scale-105`}
              >
                Short Quotes
              </button>
              <button
                aria-label="long quotes"
                onClick={() => filterQuotes("long")}
                className={` tw-px-4 tw-rounded-full tw-mr-2 tw-transition-transform tw-cursor-pointer ${
                  filterType === "long"
                    ? "tw-bg-black tw-text-white"
                    : "tw-bg-gray-300"
                } hover:tw-scale-105`}
              >
                Long Quotes
              </button>
              <button
                onClick={resetFilters}
                aria-label="long-quotes"
                className={`tw-py-1 tw-px-4 tw-rounded-full tw-mr-2 tw-transition-transform tw-cursor-pointer ${
                  filterType === ""
                    ? "tw-bg-black tw-text-white"
                    : "tw-bg-gray-300"
                } hover:tw-scale-105`}
              >
                <BiReset size={17} />
              </button>
            </div>
            <div className="">
              {isGridView ? (
                <div className="tw-grid lg:tw-grid-cols-3 tw-grid-cols-1 tw-py-6 tw-px-4 tw-gap-4">
                  {infiniteScrollData?.map((quote) => (
                    <Suspense
                      key={quote.id}
                      fallback={
                        <div className="loading-spinner">
                          <PulseLoader color="#537780" size={15} />
                        </div>
                      }
                    >
                      <div id={`quote-${quote.id}`}>
                        <Card
                          className="tw-drop-shadow-3xl"
                          quote={quote}
                          currentUser={currentUser}
                          addToFavorites={addToFavorites}
                          removeFromFavorites={removeFromFavorites}
                        />
                      </div>
                    </Suspense>
                  ))}
                </div>
              ) : (
                <div className="slider-wrapper">
                  <Slider className="tw-drop-shadow-3xl" {...settings}>
                    {filteredPageData?.map((quote) => (
                      <Suspense
                        key={quote.id}
                        fallback={
                          <div className="loading-spinner">
                            <PulseLoader color="#537780" size={15} />
                          </div>
                        }
                      >
                        <div id={`quote-${quote.id}`}>
                          <SliderCard
                            quote={quote}
                            currentUser={currentUser}
                            addToFavorites={addToFavorites}
                            removeFromFavorites={removeFromFavorites}
                          />
                        </div>
                      </Suspense>
                    ))}
                  </Slider>
                </div>
              )}
              {hasMoreItems && (
                <div ref={sentryRef} className="loading-spinner">
                  <PulseLoader color="#537780" size={15} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthorPage;
